import { Request } from '@/plugins/helper/fetch'
const shipment = new Request('shipment')
const report = new Request('report')
const pks = new Request('packages')
const pk = new Request('package')
const hawb = new Request('hawb')
const staff = new Request('staff')
const news = new Request('news')
const partner = new Request('partner')
const library = new Request('library')
const banners = new Request('banners')
export const pcustomer = new Request('pcustomer');
export const customer = new Request('customer');
export async function ListShipments(query){
  const resp = await shipment.fetch(query)
  return await resp.json()
}
export async function Shipment(id){
  const resp = await shipment.fetch(id)
  return await resp.json()
}
export async function LoadDocumentShipment(id){
  const resp = await shipment.fetch('doc/'+id)
  return await resp.json()
}
export async function LoadDocumentHawb(id){
  const resp = await hawb.fetch('doc/'+id)
  return await resp.json()
}
export async function Report(id){
  const resp = await report.fetch(id)
  return await resp.json()
}
export async function ReportBySale(id){
  const resp = await shipment.fetch(`reportBySale/${id}`)
  return await resp.json()
}
export async function InvoiceStatusByShipment(id){
  const resp = await report.fetch(`InvoiceStatus/${id}`)
  return await resp.json()
}
export async function InvoiceStatusUpdate(id,{Status,Invoice}){
  const resp = await report.patch(`statusByInvoice/${id}`,{Status,Invoice})
  return await resp.json()
}
export async function Package(id){
  const resp = await pk.fetch(id)
  return await resp.json()
}
export async function Sales(){
  const resp = await pk.fetch('list-sale')
  return await resp.json()
}
export async function Packages(id){
  const resp = await pks.fetch(id)
  return await resp.json()
}
export async function Hawb(id){
  const resp = await hawb.fetch(id)
  return await resp.json()
}
export async function Staff(id){
  const resp = await staff.fetch(id)
  return await resp.json()
}
export async function News(id){
  const resp = await news.fetch(id)
  return await resp.json()
}
export async function Partner(query){
  const resp = await partner.fetch('fetch'+query);
  return await resp.json()
}
export async function PartnerPending(query){
  const resp = await partner.fetch('pending_orders'+query);
  return await resp.json()
}
export async function Library(){
  const resp = await library.fetch();
  return await resp.json()
}
export async function Banner(){
  const resp = await banners.fetch();
  return await resp.json()
}
export async function PCustomer(query){
  const resp = await pcustomer.fetch(query);
  return await resp.json()
}
export default {
  ListShipments,
  Shipment,
  Package,
  Packages,
  Hawb,
  Sales,
  Staff,
  Report,
  ReportBySale,
  News,
  InvoiceStatusByShipment,
  InvoiceStatusUpdate,
  LoadDocumentShipment,
  LoadDocumentHawb,
  Partner,
  PartnerPending,
  Library,
  Banner,
  PCustomer,
  pcustomer,
}