<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="256px" height="256px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" v-if="status">
      <rect x="14.5" y="14.5" width="23" height="23" fill="#0051a2">
        <animate attributeName="fill" values="#1b75be;#0051a2;#0051a2" keyTimes="0;0.125;1" dur="0.8s" repeatCount="indefinite" begin="0s" calcMode="discrete"></animate>
      </rect><rect x="38.5" y="14.5" width="23" height="23" fill="#0051a2">
      <animate attributeName="fill" values="#1b75be;#0051a2;#0051a2" keyTimes="0;0.125;1" dur="0.8s" repeatCount="indefinite" begin="0.1s" calcMode="discrete"></animate>
    </rect><rect x="62.5" y="14.5" width="23" height="23" fill="#0051a2">
      <animate attributeName="fill" values="#1b75be;#0051a2;#0051a2" keyTimes="0;0.125;1" dur="0.8s" repeatCount="indefinite" begin="0.2s" calcMode="discrete"></animate>
    </rect><rect x="14.5" y="38.5" width="23" height="23" fill="#0051a2">
      <animate attributeName="fill" values="#1b75be;#0051a2;#0051a2" keyTimes="0;0.125;1" dur="0.8s" repeatCount="indefinite" begin="0.7s" calcMode="discrete"></animate>
    </rect><rect x="62.5" y="38.5" width="23" height="23" fill="#0051a2">
      <animate attributeName="fill" values="#1b75be;#0051a2;#0051a2" keyTimes="0;0.125;1" dur="0.8s" repeatCount="indefinite" begin="0.3s" calcMode="discrete"></animate>
    </rect><rect x="14.5" y="62.5" width="23" height="23" fill="#0051a2">
      <animate attributeName="fill" values="#1b75be;#0051a2;#0051a2" keyTimes="0;0.125;1" dur="0.8s" repeatCount="indefinite" begin="0.6s" calcMode="discrete"></animate>
    </rect><rect x="38.5" y="62.5" width="23" height="23" fill="#0051a2">
      <animate attributeName="fill" values="#1b75be;#0051a2;#0051a2" keyTimes="0;0.125;1" dur="0.8s" repeatCount="indefinite" begin="0.5s" calcMode="discrete"></animate>
    </rect><rect x="62.5" y="62.5" width="23" height="23" fill="#0051a2">
      <animate attributeName="fill" values="#1b75be;#0051a2;#0051a2" keyTimes="0;0.125;1" dur="0.8s" repeatCount="indefinite" begin="0.4s" calcMode="discrete"></animate>
    </rect>
    </svg>
    <slot v-else />
  </div>
</template>

<script>
export default {
  name: "loader",
  props:["status"]
}
</script>

<style scoped>

</style>
