<template>
	<v-flex>
		<v-btn
			color="green"
			class="mx-2"
			:dark="!createState"
			:loading="createState"
			:disabled="createState"
			@click="boxLibrary = true"
		>
			Thêm từ thư viện
		</v-btn>
		<v-btn
			color="green"
			class="mx-2"
			:dark="!loadingState"
			:loading="loadingState"
			:disabled="loadingState"
			@click="doFetchData"
		>
			Tải lại dữ liệu
		</v-btn>
		<v-divider class="ma-2" />
		<Loader class="justify-center align-center" :status="loadingState">
			<template v-if="loadMode && items.length > 0">
				<v-row>
					<v-col
						cols="12"
						v-for="(item, index) in items" :key="index"
					>
						<v-card>
							<v-card-title>
								<div>
									<v-img
										:src="`${statics}/${item.Image}`"
										:lazy-src="`${statics}/${item.Image}`"
										contain
										class="justify-center align-center ma-auto pa-auto"
									/>
                  <v-row>
                    <v-col
                      cols="auto"
                    >
                      <v-btn
                        color="blue"
                        :dark="topState !== item._id"
                        x-small
                        bottom
                        class="mx-2"
                        :loading="topState === item._id"
                        :disabled="topState === item._id"
                        @click="doMarkAsTop(item)"
                        v-if="item.Top === false"
                      >
                        <v-icon left>mdi-arrow-up-bold</v-icon>
                        Đặt là TOP
                      </v-btn>
                    </v-col>
                    <v-col
                        cols="auto"
                    >
                      <v-btn
                        color="green"
                        x-small
                        bottom
                        class="mx-2"
                        v-if="item.Top === true"
                        dark
                      >
                        <v-icon left>mdi-arrow-up-bold</v-icon>
                        Đang là TOP
                      </v-btn>
                    </v-col>
                    <v-col
                        cols="auto"
                    >
                      <v-btn
                        color="red"
                        :dark="removeState !== item._id"
                        x-small
                        bottom
                        class="mx-2"
                        :loading="removeState === item._id"
                        :disabled="removeState === item._id"
                        @click="doRemove(item,false)"
                      >
                        <v-icon left>mdi-delete</v-icon>
                        Xóa
                      </v-btn>
                    </v-col>
                    <v-col
                        cols="auto"
                    >
                      <v-btn
                        color="red"
                        :dark="removeState !== item._id"
                        x-small
                        bottom
                        class="mx-2"
                        :loading="removeState === item._id"
                        :disabled="removeState === item._id"
                        @click="doRemove(item,true)"
                      >
                        <v-icon left>mdi-delete</v-icon>
                        Xóa cả thư viện
                      </v-btn>
                    </v-col>
                    <v-col
                        cols="auto"
                    >
                      <v-checkbox
                        v-model="item['Main']"
                        label="Hiển thị ở trang chủ"
                        x-small
                        class="mx-2"
                        @change="doUpdateMain(item)"
                        :disabled="mainState === item._id"
                      />
                    </v-col>
                    <v-col
                        cols="auto"
                    >
                      <v-select
                        v-model="item.Services"
                        :items="Services"
                        label="Sàn"
                        multiple
                        x-small
                        class="mx-2"
                      />
                      <v-btn
                        dark
                        color="blue"
                        class="mx-2"
                        x-small
                        @click="doUpdateServices(item)"
                        :disabled="servicesState === item._id"
                        :loading="servicesState === item._id"
                      >
                        Cập nhật
                      </v-btn>
                    </v-col>
                  </v-row>
								</div>
							</v-card-title>
						</v-card>
					</v-col>
				</v-row>
			</template>
			<template v-else>
				<v-alert
					color="orange"
					type="warning"
					block
				>
					Không có dữ liệu
				</v-alert>
			</template>
		</Loader>
		<LibraryS3 :openState.sync="boxLibrary" :doSelect="doCreate" :db="items.map(item=>item['Image'])" />
	</v-flex>
</template>

<script>
import Loader from '@/components/base/loader';
import LibraryS3 from '@/components/library';
import Global from '@/global.js';
import {Banner} from '@/plugins/query/global';
import {Status} from '@/plugins/helper/dstatus';
import { Request } from '@/plugins/helper/fetch'
export default {
	name: "MyLibraryBanner",
	components:{
		Loader,
		LibraryS3,
	},
	data(){
		return {
			loadMode: false,
      servicesState: null,
      mainState: null,
			topState: null,
			createState: false,
			loadingState: false,
			removeState: null,
			items: [],
			boxLibrary: false,
		}
	},
	computed:{
		statics(){
			return `${Global.staticTOP}/images`;
		},
		Notice(){
			return new Status(this.$swal);
		},
		Query: ()=>new Request('banners'),
    Services: ()=>{
      return [
        {value:"amazon",text:"Amazon"},
        {value:"rakuten",text:"Rakuten"},
        {value:"mercari",text:"Mercari"},
        {value:"yahoo_auctions",text:"Y! Auctions"},
        {value:"yahoo_shopping",text:"Y! Shopping"},
      ]
    }
	},
	methods:{
		async doRemove(item, s3){
			const result = await this.Notice.confirm('Xác nhận ?', 'Bạn có xác nhận xóa dữ liệu ? Hành động này không thể phục hồi !!!');
			if(result) {
				const id = item._id;
				const uri = `${id}?storage=${s3}`;
				this.removeState = id;
				return this.Query.delete(uri).then(resp => resp.json()).then(json => {
					const {success, error} = json;
					if (success) {
						this.Notice.success('Xóa thành công');
						this.doFetchData();
					}
					if (error) this.Notice.error(error)
				})
					.catch(err => {
						console.error(err);
						this.Notice.error(err.message);
					})
					.finally(() => {
						this.removeState = null;
					});
			}
		},
    doUpdateServices(item){
      const id = item._id;
      this.servicesState = id;
      return this.Query.patch('services/'+id,{Services: item['Services']}).then(resp => resp.json()).then(json => {
        const {success, error} = json;
        if (success) {
          this.Notice.success('Đã cập nhật danh sách sàn !!!');
        }
        if (error) this.Notice.error(error)
      })
      .catch(err => {
        console.error(err);
        this.Notice.error(err.message);
      })
      .finally(() => {
        this.servicesState = null;
        this.doFetchData();
      });
    },
    doUpdateMain(item){
      const id = item._id;
      this.mainState = id;
      return this.Query.patch('main/'+id,{Status: item['Main']}).then(resp => resp.json()).then(json => {
        const {success, error} = json;
        if (success) {
          this.Notice.success('Thay đổi hiển thị thành công');
          this.doFetchData();
        }else{
          item.Main = !item.Main;
        }
        if (error) this.Notice.error(error)
      })
      .catch(err => {
        console.error(err);
        this.Notice.error(err.message);
      })
      .finally(() => {
        this.mainState = null;
      });
    },
		doMarkAsTop(item){
			const id = item._id;
			this.topState = id;
			return this.Query.patch('top/'+id,{}).then(resp => resp.json()).then(json => {
				const {success, error} = json;
				if (success) {
					this.Notice.success('Đặt TOP thành công');
					this.doFetchData();
				}
				if (error) this.Notice.error(error)
			})
				.catch(err => {
					console.error(err);
					this.Notice.error(err.message);
				})
				.finally(() => {
					this.topState = null;
				});
		},
		doCreate(Image){
			this.createState = true;
			this.Query.put('create',{Image})
				.then(resp=>resp.json())
				.then(json=>{
				const {success, error} = json;
				if(success) {
					this.Notice.success('Đã thêm banner thành công !!!');
					this.doFetchData();
				}
				if(error){
					this.Notice.error(error);
				}
			})
			.catch(err => {
				console.error(err);
				this.Notice.error(err.message);
			})
			.finally(() => {
				this.createState = false;
			});
		},
		doFetchData() {
			this.loadingState = true;
			Banner().then(res => {
				this.items = res.data;
				this.loadMode = true;
			})
			.catch(err => {
				console.error(err);
				this.Notice.error(err.message);
			})
			.finally(() => {
				this.loadingState = false;
			});
		},
	},
	mounted(){
		this.doFetchData();
	}
}
</script>

<style scoped>

</style>