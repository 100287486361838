<template>
	<v-dialog
		v-model="openState"
		persistent
		max-width="800px"
	>
		<v-card>
			<v-card-title>DANH SÁCH HÌNH ẢNH</v-card-title>
			<v-card-text>
				<v-btn
					color="blue"
					dark
					@click="doFetch()"
					class="mx-2"
				>
					<v-icon left>
						mdi-refresh
					</v-icon>
					Tải lại dữ liệu
				</v-btn>
				<v-file-input
					v-model="files"
					multiple
					accept="image/*"
					truncate-length="15"
					label="Tải lên hình ảnh"
					class="mx-2"
					:disabled="uploadState"
					:loading="uploadState"
				/>
				<v-divider class="ma-2" />
				<v-responsive class="overflow-auto"
					:max-height="500"
					:height="500"
				>
					<Loader :status="loadingState">
						<div class="text-h6 blue--text">
							DANH SÁCH THƯ VIỆN
						</div>
						<template v-if="items.length > 0">
							<v-row>
								<v-col
									cols="auto"
									v-for="(item, index) in items"
									:key="index"
								>
									<v-card>
										<v-card-title primary-title>
											<div>
												<v-img
													:src="`${statics}/${item}`"
													contain
													max-width="275px"
													max-height="275px"
												/>
												<v-btn color="blue" dark x-small absolute bottom right fab @click="doAction(item)" v-if="!db.includes(item)">
													<v-icon>add</v-icon>
												</v-btn>
												<v-checkbox
													v-model="Selected"
													:value="item"
													v-if="!db.includes(item)"
												/>
											</div>
										</v-card-title>
									</v-card>
								</v-col>
							</v-row>
						</template>
						<template v-else>
							<v-alert
								color="orange"
								type="warning"
								block
							>
								Không có dữ liệu
							</v-alert>
						</template>
					</Loader>
				</v-responsive>
			</v-card-text>
			<v-card-actions>
				<v-btn
					color="green"
					dark
					v-if="Selected.length > 0"
					@click="doAction(Selected)"
				>
					Thêm {{Selected.length }} hình
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn
					@click="doClose()"
					dark
				>
					Đóng
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import {Library} from '@/plugins/query/global';
import Global from '@/global.js';
import Loader from '@/components/base/loader';
import { Request } from '@/plugins/helper/fetch';
import { Status } from '@/plugins/helper/dstatus';
export default {
	name: "library-list-s3",
	components:{
		Loader,
	},
	props: {
		openState:{
			type: Boolean,
			default: false,
		},
		doSelect:{
			type: Function,
			default: ()=>{},
		},
		db: {
			type: Array,
			default: ()=>[],
		}
	},
	data() {
		return {
			Selected: [],
			files: [],
			items: [],
			loadingState: false,
			uploadState: false,
			loaded: false,
		}
	},
	computed:{
		statics(){
			return `${Global.staticTOP}/images`;
		},
		Notice(){
			return new Status(this.$swal);
		},
		Query: () => new Request('library'),
	},
	watch:{
		files(){
			if(this.files.length > 0) {
				return this.doUploadFiles();
			}
		},
		openState(){
			if(this.openState) this.Selected = [];
		}
	},
	methods:{
		doUploadFiles(){
			if(this.files.length > 0){
				this.uploadState = true;
				let Tasks = [];
				const files = this.files;
				files.map(file=>{
					Tasks.push(this.Query.UploadFile('/upload',file));
				})
				return Promise.all(Tasks).then(this.doFetch).finally(()=>{
					this.uploadState = false;
					this.files = [];
				});
			}else{
				this.Notice.error('Vui lòng chọn file để tải lên !!!');
			}
		},
		doAction(Image){
			this.doClose();
			return this.doSelect(Image);
		},
		doFetch(){
			this.loadingState = true;
			Library().then(res => {
				this.items = res.data;
				this.loaded = true;
			}).finally(()=>{
				this.loadingState = false;
			});
		},
		doClose(){
			this.$emit('update:openState', false);
		},
	},
	mounted(){
		this.doFetch();
	}
}
</script>

<style scoped>

</style>